export const headerToggle = () => {
	if(document.getElementById('mobile_hamburger_toggle')) {
		document.getElementById('mobile_hamburger_toggle').addEventListener('click', () => {
			document.querySelector('#site_header').toggleAttribute('open');
			document.querySelector('#mobile_hamburger_toggle span').toggleAttribute('open');
		});
	}
};

export const addDoubleClick = () => {
	[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
		el.addEventListener('click', e => {
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if (!el.hasAttribute('clicked')) {
					e.preventDefault();
					el.setAttribute('clicked', true);
				}
			}
		});
	});
};
