/* eslint-disable no-undef */
import { getMapsHelper } from './util/mapsHelper';

export const initMultiMap = () => {

	const mapHelper = getMapsHelper();
	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#near_map'),
				locationElementSelector: '.data_source',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
							<a class="map_pin" href="${el.getAttribute('link')}">
								<img src="/dist/images/icons/ic_locations.svg" alt="Visit facility" />
								</a>
								`
						// <span>${el.getAttribute('index')}</span>
					};
				},
			});
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				if(document.querySelectorAll('.data_source').length <= 2) {				
					theMap.setZoom(7);
				}
				document.querySelectorAll('.data_source').forEach(i => {
					const markerData = mapHelper.getMarker(i);
					markerData.element.addEventListener('mouseenter', () => {
						markerData.element.classList.add('hover');
						i.classList.add('hover');
					});
					markerData.element.addEventListener('mouseleave', () => {
						markerData.element.classList.remove('hover');
						i.classList.remove('hover');
					});
				});
			});
		});
};