import {
	AutomatitCarousel
} from './automatit_carousel.m';
import {
	getMapsHelper
} from './util/mapsHelper';

export const unitFilters = (function(){
	let my = {},
		size = null;

	function _filterUnits(){
		let noUnits = document.getElementById('no_units');
		noUnits.style.display = "block";
		[].forEach.call(document.querySelectorAll('#unit_table .unit_row'), (el) => {
			if(size === null){
				if (window.innerWidth > 768) {
					el.style.display = 'table-row';
					noUnits.style.display = "none";
				} else {
					el.style.display = 'flex';
					noUnits.style.display = "none";
				}
			}else{
				if (window.innerWidth > 768) {
					let props = el.dataset.prop;
					el.style.display = 'none';
					if(props.includes(size)){
						el.style.display = 'table-row';
						noUnits.style.display = "none";
					}
				} else {
					let props = el.dataset.prop;
					el.style.display = 'none';
					if(props.includes(size)){
						el.style.display = 'flex';
						noUnits.style.display = "none";
					}
				}
			}
		});
	}

	function _toggleFilters({currentTarget}){
		if(currentTarget.dataset.filter === "all"){
			[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
				el.classList.remove("active");
			});
			size = null;
			currentTarget.classList.add('active');
		}else{
			let active = currentTarget.parentElement.querySelector('.active');
			active.classList.remove('active');
			currentTarget.classList.add('active');
			size = currentTarget.dataset.filter;
		}

		_filterUnits();
	}

	function _toggleUnavailable() {
		if(document.getElementById('waitlist_items')) {
			let btn = document.getElementById('waitlist_items');
			let unavailableUnits = document.querySelectorAll('.unit_row');

			btn.addEventListener('click', () => {
				unavailableUnits.forEach((unit) => {
					unit.classList.remove('waitlist');
				});
				btn.style.display = 'none';
			});
		}
	}

	function _mobileFacToggle(){
		if(document.getElementById('facility_info_cta')) {
			let btn = document.getElementById('facility_info_cta');
			let panel = document.getElementById('facility_info');
			let panel1 = document.getElementById('facility_caro_container');
			// let panel2 = document.getElementById('facility_caro_toggles');
			let panel3 = document.getElementById('facility_cta');
			btn.addEventListener('click', () => {
				console.log(btn);
				console.log(panel);
				btn.classList.toggle('open');
				panel.classList.toggle('open');
				panel1.classList.toggle('open');
				// panel2.classList.toggle('open');
				panel3.classList.toggle('open');
			});
		}
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
			el.addEventListener('click', _toggleFilters);
		});
	}

	function _scrollToReviews(){
		document.getElementById('reviews').scrollIntoView();
		window.scrollBy(0, -200);
	}

	function _assignReviewScrollListener(){
		let review_btn = document.querySelector('.reviews-btn');
		review_btn.addEventListener('click', _scrollToReviews);
	}

	my.init = () => {
		if(document.getElementById('facility_size_filters')){
			_assignListeners();
			_mobileFacToggle();
		}
		if(document.getElementById('reviews_btn')){
			_assignReviewScrollListener();
		}
		_toggleUnavailable();
	};

	return my;
})();

export const facilityCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('facility_caro')) {

			if (window.slide_images.length > 1) {
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true
				};
				let caro = AutomatitCarousel(options);
				document.getElementById('caro_right_chevron').addEventListener('click', () => {
					caro.slideRight();
				});
				document.getElementById('caro_left_chevron').addEventListener('click', () => {
					caro.slideLeft();
				});
			} else {
				// let btns = document.getElementById('facility_caro_chevrons');
				// btns.parentNode.removeChild(btns);
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					imagesAsBackgrounds: true,
					intervalTiming: 0,
					useChevrons: false
				};
				AutomatitCarousel(options);
			}
		}
	};

	return my;
})();

/* eslint-disable no-undef */
export const facilityMap = (function(){
	let my = {},
		map = document.getElementById('facility_map');
	my.init = function(){
		if(document.getElementById('facility_map')){
			const mapHelper = getMapsHelper();
			mapHelper.ready()
				.then(() => {
					const theMap = mapHelper.createMap({
						element: map,
						locationElementSelector: '#facility_map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                        <span class="map_pin">
                            <svg xmlns="http://www.w3.org/2000/svg" width="43.8" height="65.65" viewBox="0 0 43.8 65.65"><g><path d="M21.9,0A21.89,21.89,0,0,0,3.54,33.83L21.9,65.65,40.27,33.83A21.91,21.91,0,0,0,21.9,0"/></g></svg>
                        </span>
                        `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
					});
				});
			
			[].forEach.call(document.querySelectorAll('.facility_caro_toggles .toggle'), (btn) => {
				btn.addEventListener('click', ({currentTarget}) => {
					[].forEach.call(document.querySelectorAll('.facility_caro_toggles .toggle'), (el) => {
						el.classList.remove('active');
					});
					currentTarget.classList.add('active');

					map.classList.remove('active');
					
					if(currentTarget.classList.contains('map')){
						map.classList.add('active');
					}

					document.getElementById('facility_video').classList.remove('active');
					document.getElementById('video_iframe').contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
					if(currentTarget.classList.contains('video')){
						document.getElementById('facility_video').classList.add('active');
						document.getElementById('video_iframe').contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
					}
				});
			});
		}
	};

	return my;
})();