import { contactSubmit } from './contact.js';
import { facilityCaro, facilityMap, unitFilters } from './facility.js';
import { addDoubleClick, headerToggle } from './header.js';
import { homeSearch } from './home.js';
import { initMultiMap } from './map.js';
import { initReviewsCaro } from './reviews.js';

headerToggle();
addDoubleClick();
unitFilters.init();
facilityCaro.init();
facilityMap.init();

homeSearch.init();

if (document.getElementById('contact_form')) {
	const file_upload = document.getElementById("file-upload");
	if (file_upload) {
		file_upload.addEventListener("change", () => {
			const file_upload_text = document.getElementById("file_upload_text");

			file_upload_text.value = file_upload.files[0].name;
			console.log(file_upload.files);

			for (let i = 1; i < file_upload.files.length; i++) {
				file_upload_text.value += `, ${file_upload.files[i].name}`;
				console.log(file_upload.files[i].value);
			}
		});
	}
	contactSubmit();
}

if(document.querySelector('#near_map')) {
	initMultiMap();
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if (document.getElementById('tips_items_wrap')) {
	setTimeout(function(){
		document.getElementsByClassName('tips_item_wrap')[0].classList.add('active');
	}, 500);
	const helpItems = document.querySelectorAll('.tips_item_wrap');
	document.getElementById('tips_items_wrap').addEventListener('click', function(e) {
		if(e.target && e.target.nodeName === "H3") {
			let el = e.target.parentElement;
			if(el.classList.contains('active')) {
				el.classList.remove('active');
			}
			else {
				helpItems.forEach(i => {
					i.classList.remove('active');
				});
				el.classList.add('active');
			}
		}
	});
}

if(document.getElementById('review_caro')){
	let viewMoreLinks = document.querySelectorAll('#review_caro .view_more');
	let viewLessLinks = document.querySelectorAll('#review_caro .view_less');
	viewMoreLinks.forEach(viewMoreLink => {
		viewMoreLink.addEventListener('click', function(e) {
			let selectedReview = e.target.closest('div');
			selectedReview.classList.add('show_more');
			document.querySelector('#review_caro .caro_inner').style.height = 'auto';
		});
	});
	viewLessLinks.forEach(viewLessLink => {
		viewLessLink.addEventListener('click', function(e) {
			let selectedReview = e.target.closest('div');
			selectedReview.classList.remove('show_more');
			document.querySelector('#review_caro .caro_inner').style.height = 'inherit';
		});
	});
}