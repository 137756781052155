/* eslint-disable no-undef */
export const homeSearch = (function(){
	let my = {};

	my.init = function(){
		if(document.getElementById('index_search_form')){
			searchHelper().bindSearchEvent(document.querySelector('#index_search_form'));
		}
	};

	return my;
})();